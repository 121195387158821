/* oswald-regular - latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/oswald-v49-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/oswald-v49-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* oswald-600 - latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../fonts/oswald-v49-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/oswald-v49-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* quattrocento-sans-regular - latin */
@font-face {
  font-family: "Quattrocento Sans";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../fonts/quattrocento-sans-v18-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/quattrocento-sans-v18-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
